// -----------------------------------------------------------------------------
// Library
// -----------------------------------------------------------------------------
import React, { useEffect, useState } from "react";
import { Field, Form } from "react-final-form";
import { Modal } from "antd";
import notification from "antd/lib/notification";

// -----------------------------------------------------------------------------
// Components and helpers
// -----------------------------------------------------------------------------
import InputField from "components/UI/Form/InputField";
import Label from "components/UI/Form/label";
import TwoFaForm from "./TwoFaForm";
import { validateEmailOrPhone, verifyPassword, validateEmail } from "helpers/Form/commonFormValidations";
import { isValidPhoneNumber } from "../../helpers/Form/commonFormValidations";
import { Paragraph9 } from "themes/default/_typography";
import { Button } from "components/Common/Button";
import { useNavigate, useParams } from "react-router";
import { useForgotPasswordMutation, useGetDomainGymQuery, useLoginMutation } from "store/api/api";
import { getClientHost } from "helpers/constants";
import { useSetRecoilState } from "recoil";
import { userAtom } from "globalAtoms";
import { getUser } from "useInit";
import { useLoginProgress, setLoginInProgress } from "utils/loginInProgress";

// -----------------------------------------------------------------------------
// Component
// -----------------------------------------------------------------------------
const LoginForm = ({ preview, gymId }) => {
	const navigate = useNavigate();
	const { gym } = useParams();
	const [loggingIn, updateLoggingIn] = useState(false);
	const [displayModal, setDisplayModal] = useState(false);
	const [potentialUser, updatePotentialUser] = useState(null);
	const [isGymReady, setIsGymReady] = useState(true);
	//TDO should be gym domain
	const { data: domainGymData } = useGetDomainGymQuery(gym);
	const [resetPasswordTrigger, resetPasswordResponse] = useForgotPasswordMutation();
	const [loginTrigger, loginResponse] = useLoginMutation();
	const setUser = useSetRecoilState(userAtom);
	const { inProgress } = useLoginProgress();

	const handleFormSubmit = async (formObj) => {
		if (inProgress) {
			return notification.error({ message: "Another login in progress in another tab. Please wait." });
		}
		setLoginInProgress(true);
		if (!preview && !loggingIn) {
			updateLoggingIn(true);
			const keyName = isValidPhoneNumber(formObj.email) ? "phone_number" : "email_address";
			await loginTrigger({
				[keyName]: formObj.email,
				password: formObj.password,
				domain: domainGymData.subdomain,
			}).unwrap();
			updateLoggingIn(false);
		}
		updateLoggingIn(false);
	};

	const handleNav = async () => {
		await getUser(setUser);
		navigate(`/${domainGymData?.subdomain}/dashboard/`);
	};

	useEffect(() => {
		if (loginResponse.isSuccess) {
			const { user, skip_2fa, auth_token } = loginResponse.data;
			if (user) {
				updatePotentialUser({ ...user, skip2fa: skip_2fa });
				if (skip_2fa) {
					localStorage.setItem("creator_jwt_token", auth_token);
					notification.success({
						message: "Login successful!",
					});
					handleNav();
				}
			}
		} else if (loginResponse.isError) {
			if (loginResponse?.error?.status === 404) {
				if (loginResponse?.error?.data?.message === "User Not Found") {
					notification.error({
						message: "Incorrect email/phone or password entered",
					});
				} else {
					setIsGymReady(false);
				}
			} else {
				notification.error({
					message: "Incorrect email/phone or password entered",
				});
			}
			setLoginInProgress(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [loginResponse]);

	const handleForgotPassword = () => {
		setDisplayModal(true);
	};

	const handleSendPassword = async ({ email }) => {
		try {
			const domain = getClientHost();
			await resetPasswordTrigger({ email, domain, gym: gym }).unwrap();
		} catch (error) {
			console.log("could not reset password", error);
		}
	};

	useEffect(() => {
		if (resetPasswordResponse.isSuccess) {
			notification.success({
				message: "Password reset email sent",
			});
			setDisplayModal(false);
		} else if (resetPasswordResponse.isError) {
			if (resetPasswordResponse.error.status === 429) {
				notification.error({
					message: "Sorry, we are unable to reset your password at this moment. Please try again later.",
				});
			} else {
				notification.error({
					message:
						"Sorry, we could not find a user with this email address. Please check your email address and try again.",
				});
			}
		}
	}, [resetPasswordResponse]);
	window.navigate = navigate;

	return (
		<div className="sb-login-form" data-id="frmLogin">
			{potentialUser ? (
				<TwoFaForm
					updateLoggingIn={updateLoggingIn}
					updatePotentialUser={updatePotentialUser}
					potentialUser={potentialUser}
					gymId={domainGymData?.id}
					subdomain={domainGymData?.subdomain}
				/>
			) : (
				<>
					{!isGymReady && <Label>This creator is still setting up their gym, check back soon!</Label>}
					{isGymReady && (
						<>
							<Form onSubmit={handleFormSubmit}>
								{({ handleSubmit }) => {
									return (
										<form id="login" onSubmit={handleSubmit}>
											<Label className="sb-login-form__forgot">Phone Number Or Email Address</Label>
											<Field component="input" name="email" validate={validateEmailOrPhone}>
												{({ input, meta }) => (
													<InputField
														disabled={preview}
														type="text"
														input={input}
														meta={meta}
														data-id="txtEmail"
														placeholder={"Phone Number or Email Address"}
													/>
												)}
											</Field>
											<Label>Password</Label>
											<Field component="input" name="password" validate={verifyPassword}>
												{({ input, meta }) => (
													<InputField
														disabled={preview}
														type="password"
														input={input}
														meta={meta}
														data-id="txtPassword"
														placeholder={"Password"}
														style={{ paddingLeft: 10 }}
													/>
												)}
											</Field>
											<label
												className="sb-login-form__forgot"
												data-id="lblForgotPassword"
												onClick={() => handleForgotPassword()}
											>
												Forgot Password?
											</label>
											<div className="sb-login-form__buttons">
												<Button
													style={{
														borderColor: domainGymData?.brand_color1 !== "" ? domainGymData?.brand_color1 : null,
													}}
													className="primary-filled"
													data-id="btnLogin"
													uppercase
													type="submit"
												>
													<Paragraph9>{loginResponse.isLoading ? "Logging In" : "Login"}</Paragraph9>
												</Button>
												<Button
													disabled={loginResponse.isLoading}
													data-id="btnSignUp"
													className="primary-filled--branding-primary-color"
													uppercase
													type="button"
													onClick={() => navigate(`../signup/${domainGymData?.subdomain}`)}
													style={{
														backgroundColor: domainGymData?.brand_color1 !== "" ? domainGymData?.brand_color1 : null,
													}}
												>
													<Paragraph9>Sign Up</Paragraph9>
												</Button>
											</div>
										</form>
									);
								}}
							</Form>
						</>
					)}
					{!loggingIn ? (
						<div className="sb-login__container__form-container__notes">
							<span className="sb-login__container__form-container__notes__first">
								Are you a gym, trainer, or studio and want to offer content to your members?
							</span>
							<a href="/creator-creation" className="sb-login__container__form-container__notes__last">
								LEARN MORE
							</a>
						</div>
					) : null}
				</>
			)}
			<Modal
				title="Forgot Password"
				style={{ display: "flex", justifyContent: "center" }}
				open={displayModal}
				onCancel={() => setDisplayModal(false)}
				footer={null}
				maskClosable={false}
				destroyOnClose
			>
				<Form onSubmit={handleSendPassword}>
					{({ handleSubmit }) => {
						return (
							<form onSubmit={handleSubmit}>
								<Field name="email" validate={validateEmail}>
									{({ input, meta }) => (
										<InputField
											disabled={preview}
											type="text"
											input={input}
											meta={meta}
											placeholder="Enter Email Address"
											data-id="txtEmail"
											style={{ width: "100%" }}
										/>
									)}
								</Field>
								<Button
									style={{ marginTop: 16, maxWidth: "100%" }}
									data-id="sb-forgot-password-send-button"
									className="primary-filled"
									key="Send"
									type="submit"
								>
									Send
								</Button>
							</form>
						);
					}}
				</Form>
			</Modal>
		</div>
	);
};

export default LoginForm;
